<template>
  <v-snackbar
    v-model="show"
    :color="color"
  >
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="show = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      message: '',
      color: ''
    }
  },

  created () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'SET_SNACKBAR') {
        this.message = state.snackbar
        this.color = state.snackbarMessage
        this.show = true
      }
    })
  }
}
</script>
