import axios from '../utils/axios'
import store from '../store'
import router from '../router'

export const loginFn = async (email, password) => {
  return await axios.post('/api/v1/users/login', {
    email,
    password
  })
}

export const getMeFn = async (redirectTo = 'Dashboard') => {
  const user = await axios('/api/v1/users/me')
  await store.dispatch('setUser', user.data.result.doc)
  await router.push({
    name: redirectTo
  })
}

export const logoutFn = async (redirectTo = 'Login') => {
  await axios('/api/v1/users/logout')
  await store.dispatch('setUser', null)
  await store.dispatch('setAuth', null)
  await store.dispatch('setSpotifyAuth', {
    token: null,
    timer: null
  })
  await router.push({
    name: redirectTo
  })
}

export const spotifyConnectFn = async () => {
  return await axios.get('/api/v1/spotify/token')
}
