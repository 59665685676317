<template>
  <div class="pointer" :class="{home: isHome}">
    <v-img @click="navigate" aspect-ratio="1" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" :src="artist.ytChannel.thumbnails[0].medium.url" class="white--text rounded-xl">
    </v-img>
    <v-list-item two-line>
      <v-list-item-content class="pt-1">
        <v-list-item-title>{{ artist.ytChannel.title }}</v-list-item-title>
<!--        <v-list-item-subtitle><span class="mr-2" v-for="artist in track.spotify.artists" :key="artist.id">{{ artist }}</span></v-list-item-subtitle>-->
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  props: {
    artist: {
      type: Object,
      default: () => {}
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isHome: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      done: false,
      loading: false
    }
  },
  methods: {
    navigate () {
      this.$router.push({ name: 'ArtistCovers', params: { slug: this.artist.slug } })
    }
  }
}
</script>
<style lang="scss">
  .home {
    width: 150px;
  }
</style>
