<template>
  <v-app id="access">
    <v-main class="mt-5">
      <router-view></router-view>
      <Snackbar />
    </v-main>
  </v-app>
</template>

<script>
import breakPoints from '../mixins/breakPoints'
import Snackbar from '../components/Snackbar'
export default {
  data () {
    return {
      drawer: false,
      switch1: false
    }
  },
  mixins: [breakPoints],
  components: {
    Snackbar
  }
}
</script>
<style lang="scss" scoped>
  h2 {
    cursor: pointer;
  }
</style>
