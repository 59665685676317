import date from 'date-and-time'

export const moment = (value) => {
  if (!value) return ''
  const parsedDate = date.parse(value, 'YYYY-MM-DD')
  return date.format(parsedDate, 'MMM Y')
}

export const duration = d => {
  d = Number(d / 1000)
  const m = Math.floor(d % 3600 / (60))
  const s = Math.floor(d % 3600 % (60))
  return ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2)
}
