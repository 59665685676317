<template>
  <div class="pointer" :class="{home: isHome}">
    <v-img @click="navigate" aspect-ratio="1" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" :src="track.spotify.images[1].url" class="white--text rounded-xl">
      <div class="d-flex justify-space-between px-3 py-2">
        <v-icon color="#191414" class="bg-white">mdi-spotify</v-icon>
        <v-spacer></v-spacer>
<!--        <span>{{ duration(track.spotify.duration) }}</span>-->
      </div>
    </v-img>
    <v-list-item two-line>
      <v-list-item-content class="pt-1">
        <v-list-item-title>{{ track.spotify.name }}</v-list-item-title>
        <v-list-item-subtitle><span class="mr-2" v-for="artist in track.spotify.artists" :key="artist.id">{{ artist }}</span></v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import { duration } from '../../../utils/time'
export default {
  props: {
    track: {
      type: Object,
      default: () => {}
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isHome: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      done: false,
      loading: false
    }
  },
  methods: {
    duration (d) {
      return duration(d)
    },
    navigate () {
      if (this.isAdmin) {
        this.$router.push({ name: 'CoversListAdmin', params: { pid: this.track._id } })
      } else {
        this.$router.push({ name: 'CoversList', params: { slug: this.track.slug } })
      }
    }
  }
}
</script>
<style lang="scss">
  .home {
    width: 150px;
  }
</style>
