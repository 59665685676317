<template>
  <v-card dark class="mb-10 rounded-xl">
    <v-row>
      <v-col :cols="12" :lg="5" class="py-0">
        <div class="left-side">
          <v-img gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,1)"
                 :src="cover.ost.spotify.images[0].url" class="d-flex rounded-xl">
            <div class="song-container">
              <div class="d-flex align-end">
                <div>
                  <router-link :to="{name: 'SingleCoverSong', params: {slug: cover.slug}}">
                    <v-img class="rounded-xl abc" :src="cover.artists[0].ytChannel.thumbnails[0].default.url"></v-img>
                  </router-link>
                </div>
                <div>
                  <router-link :to="{name: 'SingleCoverSong', params: {slug: cover.slug}}" class="text-decoration-none">
                    <v-card-title class="headline break-text white--text pb-0">{{ `${cover.ost.spotify.name} | ${cover.ost.spotify.album}` }}</v-card-title>
                  </router-link>

                  <router-link :to="{name: 'ArtistCovers', params: {slug: cover.artists[0].slug }}" class="text-decoration-none">
                    <v-card-subtitle class="py-0">{{ cover.artists[0].ytChannel.title }}</v-card-subtitle>
                  </router-link>
                </div>
              </div>
            </div>
          </v-img>
        </div>
      </v-col>
      <v-col :cols="12" :lg="7">
        <div class="d-flex flex-column pa-10 pl-5 h-100 justify-center">
            <h1 class="display-3 font-weight-bold rubik">Find Cover songs of all the latest tracks</h1>
            <ul class="mt-10">
              <li>Latest covers of upcoming artists</li>
              <li>Best way to reach Music Lovers</li>
              <li>Make your presence online</li>
            </ul>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    cover: {
      type: Object,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
.left-side {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  flex-direction: column;
}
.abc {
  width: 100px;
}
  .song-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding: 1rem;
  }
</style>
