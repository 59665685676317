import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/covers/:slug',
    name: 'SingleCoverSong',
    component: () => import(/* webpackChunkName: "website" */ '../views/Covers/Single')
  },
  {
    path: '/tracks/:page?',
    name: 'TracksList',
    props: true,
    component: () => import(/* webpackChunkName: "website" */ '../views/Tracks')
  },
  {
    path: '/track/:slug',
    name: 'OST',
    component: () => import(/* webpackChunkName: "website" */ '../views/Covers/OSTSong'),
    children: [
      {
        path: 'covers/:page?',
        name: 'CoversList',
        props: true,
        component: () => import(/* webpackChunkName: "website" */ '../views/Covers/List')
      }
    ]
  },
  {
    path: '/artists/:page?',
    name: 'ArtistsList',
    component: () => import(/* webpackChunkName: "website" */ '../views/Artists/List')
  },
  {
    path: '/artist/:slug/',
    name: 'ArtistProfile',
    component: () => import(/* webpackChunkName: "website" */ '../views/Artists/Profile'),
    children: [
      {
        path: 'covers/:page?',
        name: 'ArtistCovers',
        props: true,
        component: () => import(/* webpackChunkName: "website" */ '../views/Artists/ArtistCovers')
      }
    ]
  },
  {
    path: '/admin',
    meta: {
      requiresAuth: true,
      isAdmin: true
    },
    component: () => import(/* webpackChunkName: "user" */ '../views/Admin'),
    children: [
      {
        path: 'db/:page?',
        name: 'AdminDashboard',
        props: true,
        component: () => import(/* webpackChunkName: "user" */ '../views/Admin/Dashboard')
      },
      {
        path: 'tracks/add',
        name: 'AddTrackAdmin',
        component: () => import(/* webpackChunkName: "user" */ '../views/Admin/AddTrack')
      },
      {
        path: 'tracks/:page?',
        name: 'TracksListAdmin',
        props: true,
        component: () => import(/* webpackChunkName: "user" */ '../views/Admin/TracksList')
      },
      {
        path: 'tracks/:pid/covers/:page?',
        name: 'CoversListAdmin',
        component: () => import(/* webpackChunkName: "user" */ '../views/Admin/CoversListing')
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "static" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      layout: 'Access',
      guest: true
    },
    component: () => import(/* webpackChunkName: "static" */ '../views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "user" */ '../views/Dashboard.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // scrollBehavior (to, from, savedPosition) {
  //   if (to.name === 'feed' || to.name === 'profiles') {
  //     if (savedPosition) {
  //       return { y: savedPosition.y }
  //     } else {
  //       return { x: 0, y: 0 }
  //     }
  //   }
  //   return { x: 0, y: 0 }
  // },
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return window.scrollTo({ top: document.querySelector(to.hash).offsetTop, behavior: 'smooth' })
    }
    return window.scrollTo({ top: 0, behavior: 'smooth' })
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.user) {
      next({
        name: 'Login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      const user = store.state.user
      if (to.matched.some(record => record.meta.isAdmin)) {
        if (user.role === 'admin') {
          next()
        } else {
          next({ name: 'Dashboard' })
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (!store.state.user) {
      next()
    } else {
      next({ name: 'Dashboard' })
    }
  } else {
    next()
  }
})

export default router
