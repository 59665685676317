import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import snackbarPlugin from './plugins/snackbar'
import '@babel/polyfill'
import '@mdi/font/css/materialdesignicons.css'
import axios from './utils/axios'
import VueAxios from 'vue-axios'
import { moment } from './utils/time'
import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'

// Custom
import './assets/scss/main.scss'

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(snackbarPlugin, { store })
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.use(VueGtag, {
  config: { id: 'UA-139236325-1' }
}, router)

// Creating a date time filter
Vue.filter('moment', moment)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
