<template>
  <v-app-bar app clipped-left clipped-right elevation="0">
<!--    <v-app-bar-nav-icon @click="$emit('drawerClicked')" v-if="$vuetify.breakpoint.mdAndDown"></v-app-bar-nav-icon>-->
    <router-link
      :to="{ name: isAdmin && isLoggedIn ? 'AdminDashboard' : 'Home' }"
      exact
    >
      <img class="mt-2 mr-4" :src="require('@/assets/images/logo.svg')" width="30" alt="logo"/>
    </router-link>
    <v-row>
      <v-col>
        <div class="relative">
          <v-text-field
            :loading="loading"
            @blur="blur"
            label="Search Tracks or Artists"
            append-icon="mdi-magnify"
            v-model="search"
            :class="{'rounded-b-0': tracks || artists}"
            hide-details
            :solo="!dark"
            :solo-inverted="dark"
          ></v-text-field>
          <div class="songs-list" v-if="loading">
            <div class="elevation-2 rounded-b-xl">
              <div>
                <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
                <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
                <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
              </div>
            </div>
          </div>
          <div class="songs-list" v-else>
            <div class="elevation-2 rounded-b-xl">
              <v-list class="list">
                <v-list-item-group v-if="tracks && tracks.length > 0">
                  <v-list-item-title class="px-5 py-2 text-uppercase heading secondary small darken-1 background">Cover Songs</v-list-item-title>
                  <v-list-item v-for="track in tracks" @click="redirect(track.slug, 'track', track._id)" :key="track.id">
                    <v-list-item-icon><v-icon>mdi-music</v-icon></v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="track.spotify.name"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="text-caption"><b>TRACK</b></v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item-group v-else-if="tracks !== null">
                  <v-list-item-title class="px-5 py-2 text-uppercase heading secondary small darken-1 background">Cover Songs</v-list-item-title>
                  <v-list-item>
                    <v-list-item-icon><v-icon>mdi-music</v-icon></v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>No Covers found</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-list class="list">
                <v-list-item-group v-if="artists && artists.length > 0">
                  <v-list-item-title class="px-5 py-2 text-uppercase heading secondary small darken-1 background">Artists</v-list-item-title>
                  <v-list-item v-for="artist in artists" @click="redirect(artist.slug, 'artist', artist._id)" :key="artist.id">
                    <v-list-item-icon><v-icon>mdi-account-music</v-icon></v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="artist.ytChannel.title"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="text-caption"><b>ARTIST</b></v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item-group v-else-if="artists !== null">
                  <v-list-item-title class="px-5 py-2 text-uppercase heading secondary small darken-1 background">Artists</v-list-item-title>
                  <v-list-item>
                    <v-list-item-icon><v-icon>mdi-music</v-icon></v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>No Artists found</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-btn
      v-if="isAdmin"
      :to="{ name: 'AddTrackAdmin' }"
      :icon="$vuetify.breakpoint.smAndDown"
      color="secondary"
      class="mr-2"
    >
      <v-icon>mdi-plus</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">Add Song</span>
    </v-btn>
    <v-btn
      :to="{ name: 'ArtistsList' }"
      :icon="$vuetify.breakpoint.smAndDown"
      color="secondary"
      class="mr-2"
    >
      <v-icon>mdi-account-music</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">All Artists</span>
    </v-btn>
    <v-btn
      :to="{ name: 'TracksList' }"
      :icon="$vuetify.breakpoint.smAndDown"
      color="secondary"
    >
      <v-icon>mdi-music</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">All Tracks</span>
    </v-btn>
<!--    <v-btn-->
<!--      link-->
<!--      :icon="$vuetify.breakpoint.smAndDown"-->
<!--      text-->
<!--      class="ml-1 text&#45;&#45;secondary"-->
<!--    >-->
<!--      <v-icon>mdi-bell-outline</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">Notifications</span>-->
<!--    </v-btn>-->
<!--    <v-avatar size="40" v-if="currentUser && currentUser.meta">-->
<!--      <v-img :src="currentUser.meta.et_avatar_url"></v-img>-->
<!--    </v-avatar>-->
<!--    <v-avatar size="40" v-else color="primary">-->
<!--    </v-avatar>-->

    <v-btn text v-if="!isLoggedIn && isAdmin" :to="{ name: 'Login' }">
      Login
    </v-btn>
    <v-btn @click="changeTheme()" icon class="ml-2">
      <v-icon v-show="!dark" color="black">mdi-weather-night</v-icon>
      <v-icon v-show="dark" color="yellow">mdi-white-balance-sunny</v-icon>
    </v-btn>
<!--    <v-menu left bottom offset-y min-width="200">-->
<!--      <template v-slot:activator="{ on, attrs }">-->
<!--        <v-btn icon v-bind="attrs" v-on="on" class="ml-1 text&#45;&#45;secondary">-->
<!--          <v-icon>mdi-dots-horizontal</v-icon>-->
<!--        </v-btn>-->
<!--      </template>-->
<!--      <v-list>-->
<!--        <v-list-item :to="{name: 'Dashboard'}" v-if="isAdmin">-->
<!--          <v-list-item-title>-->
<!--            <v-icon class="mr-1">mdi-account</v-icon> My Profile-->
<!--          </v-list-item-title>-->
<!--        </v-list-item>-->
<!--        <v-list-item @click="changeTheme()" >-->
<!--          <v-list-item-title v-show="!dark">-->
<!--            <v-icon class="mr-1">mdi-weather-night</v-icon> Dark Mode-->
<!--          </v-list-item-title>-->
<!--          <v-list-item-title v-show="dark">-->
<!--            <v-icon class="mr-1">mdi-white-balance-sunny</v-icon> Light Mode-->
<!--          </v-list-item-title>-->
<!--        </v-list-item>-->
<!--        <v-list-item @click="logout" v-if="isAdmin">-->
<!--          <v-list-item-title>-->
<!--            <v-icon class="mr-1">mdi-exit-to-app</v-icon> Logout-->
<!--          </v-list-item-title>-->
<!--        </v-list-item>-->
<!--      </v-list>-->
<!--    </v-menu>-->
  </v-app-bar>
</template>

<script>
import { logoutFn } from '../helpers/authentication'
import Debounce from 'debounce'
export default {
  data () {
    return {
      dark: localStorage.getItem('GB_DARK') === 'true',
      loading: false,
      tracks: null,
      artists: null,
      search: null,
      query: null,
      debounceSearch: null
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.state.user
    },
    isAdmin () {
      return this.$store.getters.isAdmin
    }
  },
  mounted () {
    this.debounceSearch = Debounce(this.searchTracks, 300)
  },
  props: {
    drawer: {
      default: null
    },
    currentUser: {
      default: () => {},
      type: Object
    }
  },
  methods: {
    redirect (slug, type, id) {
      this.search = null
      this.tracks = []
      this.artists = []
      if (this.isAdmin && type !== 'artist') {
        this.$router.push({
          name: 'CoversListAdmin',
          params: {
            pid: id
          }
        })
      } else {
        const routeName = type === 'artist' ? 'ArtistCovers' : 'CoversList'
        this.$router.push({
          name: routeName,
          params: {
            slug: slug
          }
        })
      }
    },
    changeTheme () {
      this.$vuetify.theme.dark = !this.dark
      localStorage.setItem('GB_DARK', !this.dark)
      this.dark = !this.dark
    },
    async searchTracks () {
      try {
        if (this.search && this.search.length > 1) {
          this.loading = true
          const res = await this.axios.post('/api/v1/tracks/search/', {
            query: this.search
          })
          this.tracks = res.data.songs
          this.artists = res.data.artists
        }
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async logout () {
      await logoutFn()
    },
    blur () {
      setTimeout(() => {
        this.search = ''
        this.tracks = this.artists = null
      }, 200)
    }
  },
  watch: {
    search (val) {
      if (val && val.length > 1 && val !== this.query) {
        this.loading = true
        this.debounceSearch()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.songs-list {
  position: absolute;
  left: 0;
  right: 0;
  & > div {
    overflow-y: scroll;
    max-height: 500px;
  }
  .list {
    padding-top: 0;
    padding-bottom: 0;
  }
}

</style>
