import axios from 'axios'
import { logoutFn } from '../helpers/authentication'
import store from '../store'

if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://api.coversingers.com/'
}

// axios.defaults.withCredentials = true // default
axios.interceptors.request.use(function (config) {
  const { token, spotifyToken } = store.state
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  if (spotifyToken) {
    config.headers.SpotifyAuthorization = `Bearer ${spotifyToken}`
  }
  return config
})

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response.status === 401) return logoutFn('Login')
  return Promise.reject(error)
})

export default axios
