<template>
  <v-app id="cs" :style="{background: bg}">
    <app-bar
      :drawer="drawer"
      @drawerClicked="drawer = !drawer"
      :style="{background: header}"
    ></app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <Snackbar />
  </v-app>
</template>

<script>
import AppBar from '@/components/AppBar/'
import breakPoints from '../mixins/breakPoints'
import Snackbar from '../components/Snackbar'

export default {
  components: {
    AppBar,
    Snackbar
  },
  props: {
    fillMobile: {
      type: Boolean,
      default: false
    },
    hideLeftSidebar: {
      type: Boolean,
      default: false
    },
    hideRightSidebar: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      drawer: false,
      rightDrawer: false
    }
  },
  mixins: [breakPoints]
}
</script>
