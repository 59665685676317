<template>
  <div>
    <v-row v-if="loading">
      <v-col :lg="2" :cols="6" :md="3" :sm="4" :xl="2" v-for="i in limit" :key="i.id">
        <v-skeleton-loader
          elevation="0"
          class="rounded-xl"
          v-bind="attrs"
          type="image, list-item"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <div v-else>
      <div class="text-uppercase d-flex justify-space-between mb-5">
        <h2>New Artists on CoverSingers</h2>
        <router-link class="text-decoration-none font-weight-bold d-flex align-center" :to="{name: 'ArtistsList'}">
          View All <v-icon color="secondary">mdi-arrow-right</v-icon>
        </router-link>
      </div>
      <div class="row-songs">
        <div v-for="artist in artists" :key="artist.id" class="item">
          <single-item :artist="artist" v-if="artist" is-home class="ma-1"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleItem from '../../components/ArtistsComponents/SingleItem'

export default {
  props: {
    page: {
      type: Number,
      default: 1
    },
    artists: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: true
    },
    limit: {
      type: Number,
      default: 18
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      attrs: {
        elevation: 1
      }
    }
  },
  components: {
    SingleItem
  }
}
</script>

<style scoped lang="scss">
  .row-songs {
    display: flex;
    overflow-x: scroll;
    .item {
      flex-basis: 30px;
      flex-shrink: 1;
      margin-right: 1rem;
    }
  }
</style>
