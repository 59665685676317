import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['user', 'token', 'spotifyToken', 'spotifyTimer']
  })],
  state: {
    user: null,
    snackbarContent: '',
    snackbarColor: '',
    token: null,
    spotifyToken: null,
    spotifyTimer: null
  },
  getters: {
    isAdmin (state) {
      return state.user && state.user.role === 'admin'
    },
    authToken (state) {
      return state.token
    }
  },
  mutations: {
    SET_USER (state, data) {
      state.user = data
    },
    SET_SNACKBAR (state, data) {
      state.snackbar = data.content
      state.snackbarMessage = data.color
    },
    UNSET_SNACKBAR (state) {
      state.snackbar = false
      state.snackbarMessage = null
    },
    SET_AUTH (state, data) {
      state.token = data
    },
    SET_SPOTIFY_AUTH (state, data) {
      state.spotifyToken = data.token
      state.spotifyTimer = data.timer
    }
  },
  actions: {
    setUser ({ commit }, payload) {
      commit('SET_USER', payload)
    },
    setSnackbar ({ commit }, payload) {
      commit('SET_SNACKBAR', payload)
    },
    unsetSnackbar ({ commit }) {
      commit('UNSET_SNACKBAR')
    },
    setAuth ({ commit }, payload) {
      commit('SET_AUTH', payload)
    },
    setSpotifyAuth ({ commit }, payload) {
      commit('SET_SPOTIFY_AUTH', payload)
    }
  },
  modules: {
  }
})
