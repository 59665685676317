<template>
  <div>
    <Component :is="component"/>
  </div>
</template>

<script>
import DefaultLayout from './Layout/DefaultLayout'
import VideoLayout from './Layout/VideoLayout'
import Access from './Layout/Access'

export default {
  computed: {
    component () {
      return this.$route.meta.layout ? this.$route.meta.layout : 'DefaultLayout'
    }
  },
  mounted () {
    this.setTheme()
  },
  methods: {
    setTheme () {
      // const mode = localStorage.getItem('GB_DARK')
      // if (!mode || typeof mode === 'undefined') {
      //   localStorage.setItem('GB_DARK', true)
      // }
    }
  },
  components: {
    DefaultLayout,
    VideoLayout,
    Access
  }
}
</script>
