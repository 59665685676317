<template>
  <v-app id="cs" :style="{background: bg}">
    <app-bar
      :drawer="drawer"
      @drawerClicked="drawer = !drawer"
      :style="{background: header}"
    ></app-bar>
<!--    <v-navigation-drawer app v-model="drawer" clipped :permanent="!lgScreen" floating :style="{background: bg}" width="300" :mini-variant="!$vuetify.breakpoint.smAndDown" v-show="!hideLeftSidebar">-->
<!--      <sidebar ref="navigation" />-->
<!--    </v-navigation-drawer>-->
    <v-main class="mt-5">
      <v-container class="mx-auto" :class="{'mobile-height' : fillMobile}">
<!--        <v-container fluid class="px-1 px-sm-5 pb-0">-->
<!--          <v-alert :tile="$vuetify.breakpoint.smAndDown" icon="mdi-shield-alert" color="error" dark class="mb-0 text-body-2" elevation="1">Please verify your email to start using Gradbee.</v-alert>-->
<!--        </v-container>-->
        <router-view></router-view>
      </v-container>
    </v-main>
<!--    <v-navigation-drawer v-model="rightDrawer" app clipped :permanent="!lgScreen" floating right :style="{background: bg}" width="300" v-show="!hideRightSidebar">-->
      <!--      <sidebar ref="rightSidebar" />-->
<!--    </v-navigation-drawer>-->
    <Snackbar />
  </v-app>
</template>

<script>
// import Sidebar from '@/components/Sidebar/'
import AppBar from '@/components/AppBar/'
import breakPoints from '../mixins/breakPoints'
import Snackbar from '../components/Snackbar'

export default {
  components: {
    // Sidebar,
    AppBar,
    Snackbar
  },
  props: {
    fillMobile: {
      type: Boolean,
      default: false
    },
    hideLeftSidebar: {
      type: Boolean,
      default: false
    },
    hideRightSidebar: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      drawer: false,
      rightDrawer: false
    }
  },
  mixins: [breakPoints]
}
</script>
