<template>
  <div class="admin-tracks-list">
    <featured :cover="featuredCover" v-if="featuredCover"/>
    <track-listing
      :songs="songs"
      class="mb-10"
      :limit="limit"
      :loading="ostLoading"
      :isAdmin="false" />
    <artists-listing
      :artists="artists"
      class="mb-10"
      :limit="limit"
      :isAdmin="false"
      :loading="artistsLoading" />
  </div>
</template>

<script>
import Featured from '../components/Home/Featured'
import TrackListing from '../components/Home/OSTList'
import ArtistsListing from '../components/Home/ArtistList'

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Find Cover Songs of all the Latest tracks',
    // all titles will be injected into this template
    titleTemplate: '%s | CoverSingers'
  },
  data () {
    return {
      songs: [],
      artists: [],
      featuredCover: null,
      error: false,
      track: null,
      limit: 12,
      ostLoading: true,
      artistsLoading: true,
      coversLoading: true,
      total: 0
    }
  },
  async mounted () {
    this.fetchSongs()
    this.fetchArtists()
    this.fetchFeaturedCovers()
  },
  methods: {
    async fetchSongs () {
      try {
        const songData = await this.axios.get('/api/v1/tracks/')
        this.songs = songData.data.data.songs
        this.total = songData.data.total
      } catch (e) {
        return e
      } finally {
        this.ostLoading = false
      }
    },
    async fetchArtists () {
      try {
        this.artistsLoading = true
        const artistsData = await this.axios.get('/api/v1/artists/')
        this.artists = artistsData.data.data.artists
        this.total = artistsData.data.total
      } catch (e) {
        return e
      } finally {
        this.artistsLoading = false
      }
    },
    async fetchFeaturedCovers () {
      try {
        this.loading = true
        const songData = await this.axios.get('/api/v1/covers/featured/')
        this.featuredCover = songData.data.data.covers[0]
      } catch (e) {
        this.$snackbar.showMessage({ content: e.response.data.message })
        this.error = true
      } finally {
        this.loading = false
      }
    }
  },
  components: {
    TrackListing,
    ArtistsListing,
    Featured
  }
}
</script>
