<template>
  <div>
    <v-row v-if="loading">
      <v-col :lg="2" :cols="6" :md="3" :sm="4" :xl="2" v-for="i in limit" :key="i.id">
        <v-skeleton-loader
          elevation="0"
          class="rounded-xl"
          v-bind="attrs"
          type="image, list-item"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <div v-else>
      <div class="text-uppercase d-flex justify-space-between mb-5">
        <h2>Newly Added cover songs</h2>
        <router-link class="text-decoration-none font-weight-bold d-flex align-center" :to="{name: 'TracksList'}">
          View All <v-icon color="secondary">mdi-arrow-right</v-icon>
      </router-link>
      </div>
      <div class="row-songs">
        <div v-for="track in songs" :key="track.id" class="item">
          <single-track-card :track="track" v-if="track" class="ma-1" is-home/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleTrackCard from '../../components/Admin/TrackListComponents/SingleTrackCard'

export default {
  props: {
    songs: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: true
    },
    limit: {
      type: Number,
      default: 18
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      attrs: {
        elevation: 1
      }
    }
  },
  components: {
    SingleTrackCard
  }
}
</script>

<style scoped lang="scss">
.row-songs {
  display: flex;
  overflow-x: scroll;
  .item {
    flex-basis: 30px;
    flex-shrink: 1;
    margin-right: 1rem;
  }
}
</style>
